@import '@/themes/_variables.scss';

.not-supported-modal {
    padding: 1.5rem;
    max-width: 600px;
    box-shadow: 0 0 0 3px $danger;
    outline: none;
    border-radius: 10px;
    text-align: center;

    p {
        margin: 1rem 0;
    }
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}
