@import "@/themes/_variables.scss";

footer {
  background: $into-black;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .footer {
    &__text {
      font-size: 0.8rem;
      color: $white;
    }
  }
  a {
    text-decoration: none;
    color: $white;
  }
}
