@import '@/themes/_variables.scss';

.file-details {
    background-color: $masonry;
    border-radius: 10px;

    &__file-wrapper {
        display: grid;
        gap: 2rem;
        padding: 1rem;
    }

    &__file {
        display: grid;
        grid-template: auto / 1fr auto;
        gap: 1rem;
        align-items: center;

        &__title {
            font-weight: bold;
            color: $information-dark;
            word-break: break-word;
        }
    }
}
