@import '@/themes/_variables.scss';

.progress-details {
    background-color: $masonry;
    border-radius: 10px;

    &__wrapper {
        display: grid;
        grid-template: auto / auto 1fr;
        gap: 1rem 2rem;
        padding: 1rem;
    }

    &__title {
        font-weight: bold;
        color: $information-dark;
        word-break: break-word;
    }
}
