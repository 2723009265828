@import './variables.scss';
@import 'bootstrap/scss/bootstrap';
@import './styles.scss';

main {
    padding: 1rem 1rem 5rem;
}

.limit-width {
    max-width: 1024px;
    margin: auto;
}
