@mixin button-basic {
    padding: 0.5rem 1rem;
    border-radius: 10px;
    text-decoration: none;
    display: inline-block;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    }
}

@mixin button-green {
    @include button-basic;
    color: $white;
    border: 1px solid $success;
    background-color: $success;
}

@mixin button-sack-cloth {
    @include button-basic;
    color: $white;
    border: 1px solid $sack-cloth;
    background-color: $sack-cloth;
}

@mixin button-twilight {
    @include button-basic;
    color: $white;
    border: 0;
    background-color: $twilight;
    font-weight: 600;
}

@mixin button-red {
    @include button-basic;
    color: $white;
    border: 1px solid $danger;
    background-color: $danger;
}
