@import "@/themes/_variables.scss";
@import "@/themes/_mixins.scss";


.home-link {
  @include button-green;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: $white;
  }
}

