@import '@/themes/_variables.scss';
@import '@/themes/_mixins.scss';

.actionbutton {
    white-space: nowrap;
}

@mixin action-buttons {
    display: flex;
    align-items: center;

    &:hover {
        color: $white;
    }
}

.proceed-button {
    @include button-green;
    @include action-buttons;
}

.disabled-button {
    @include button-sack-cloth;

    img {
        display: none;
    }
}

.close-button {
    @include button-twilight;
    margin-right: 1rem;
}
