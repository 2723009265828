@import '@/themes/_variables.scss';

.progress-bar,
.progress-completed {
    background-color: transparent;
    border-radius: 0.25rem;
    color: black;

    .progress-bar__multi {
        background-color: $white;
        height: 1.5rem;

        &__success {
            background-color: $success;
        }

        &__error {
            background-color: $danger;
        }
    }
}
