@import '@/themes/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.dropzone {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-radius: 10px;
    background-color: $information-light;
    outline: none;
    cursor: pointer;
    min-height: 30rem;
    font-size: 1.75rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    transition: min-height 0.3s ease, box-shadow 0.3s ease-in-out;

    @include media-breakpoint-up(sm) {
        padding: 2rem;
    }

    &:hover {
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    }

    &.dropzone-active {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    }

    .dropzone-content {
        display: grid;
        grid-template: auto / auto;
        justify-items: center;
        align-items: center;
    }

    .dropzone-text {
        font-size: 20px;
        font-weight: bold;
        color: $twilight;
        margin-bottom: 0;
        text-align: center;

        @include media-breakpoint-up(sm) {
            font-size: 28px;
        }

        > span {
            display: block;
            font-size: 1rem;
        }
    }

    &--small {
        min-height: 6rem;
        font-size: 1rem;
        padding: 1rem 2rem;

        .dropzone-content {
            grid-template: auto / 1fr auto;
            gap: 1rem;
            justify-items: left;
        }

        .dropzone-text {
            font-size: 16px;

            > span {
                font-size: 12px;
            }
        }
    }
}
