/*Theme Colors*/
$twilight: #455556;
$masonry: #e4e6e8;
$into-black: #001a21;
$danger: #a90f03;
$danger-light: #fdeae9;
$success: #21ba45;
$success-dark: #007543;
$success-light: #e6f7f0;
$information: #0084a9;
$information-dark: #003e5e;
$information-light: #e4f1f5;
$bg-blue: #005c75;
$sack-cloth: #b5aea7;
$white: #fff !default;
$form-check-input-checked-bg-color: #54b8b1;
$form-check-input-checked-border-color: #54b8b1;
