@import '@/themes/_variables.scss';

.error-modal {
    max-width: 500px;
    max-height: 80%;
    overflow: auto;
    font-size: 14px;

    ul {
        padding-left: 1rem;
        margin: 1.5rem 0;
    }
}
