html {
    -webkit-font-smoothing: antialiased;
    position: relative;
    min-height: 100%;
}

body {
    font-family: 'Helvetica Neue', helvetica, arial, 'Lucida Grande', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
}

h1 {
    font-size: 50px;
}
h2 {
    font-size: 24px;
}
h3 {
    font-size: 20px;
}
h4 {
    font-size: 16px;
}
h5 {
    font-size: 14px;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 700;
    line-height: 1;
    margin: 0;
}

p {
    font-size: 14px;
}

.font-bold {
    font-weight: bold;
}

.font-italic {
    font-style: italic;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.25);
}

.modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    border: none;
    background-color: white;
    padding: 1rem 1.5rem;
}
