@import '@/themes/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

header {
    position: relative;
    background: $into-black;

    .navbar {
        min-height: 114px;
        display: grid;
        grid-template: auto / auto;
        gap: 0.5rem;
        padding: 1.5rem 1rem;

        @include media-breakpoint-up(sm) {
            grid-template: auto / auto 1fr;
            gap: 2rem;
            padding: 1rem;
        }

        &__logo {
            max-width: 100%;
        }

        &__title {
            color: $white;
            font-size: 1.7rem;
            font-weight: 700;
        }
    }

    .version {
        font-size: 0.8rem;
        text-decoration: none;

        a {
            text-decoration: none;
            color: #fff;
        }
    }
}
