@import '@/themes/_variables.scss';

.process-title {
    display: grid;
    grid-template: auto / 1fr auto;
    gap: 1rem;
    align-items: center;
    background-color: $bg-blue;
    color: $white;
    border-radius: 10px 10px 0 0;
    padding: 1rem;
    min-height: 74px;

    &-info {
        display: grid;
        gap: 0.5rem;
    }

    .form-check {
        display: grid;
        grid-template: auto/ auto 1fr;
        gap: 0.5rem;
        align-items: center;
        margin: 0;
        padding: 0;

        .form-check-input {
            height: 18px;
            width: 18px;
            margin: 0;
        }
    }
}
